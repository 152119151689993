import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import useContactLists from "../../hooks/useContactLists";
import ConfirmationModal from "../ConfirmationModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash/debounce";
import useCampaigns from "../../hooks/useCampaigns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ContactListSelectModal = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedListId, setSelectedListId] = useState(null);
  const { list: listContactList } = useContactLists();
  const [confirmCreate, setConfirmCreate] = useState(false);

  const [statsCampaigns, setStatsCampaigns] = useState(null); 
  const { findPermissions } = useCampaigns();
  
  const fetchStatsCampaigns = async () => {
    try {
      setLoading(true);
      const data = await findPermissions();
      setStatsCampaigns(data); 
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchContactLists("");
      fetchStatsCampaigns();
    }
    // eslint-disable-next-line
  }, [open]);

  const fetchContactLists = async (searchTerm) => {
    setLoading(true);
    try {
      const data = await listContactList();
      if (Array.isArray(data)) {
        setLists(
          data.filter((list) =>
            list.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      } else {
        console.error("Unexpected response format:", data);
        toast.error("Erro ao buscar listas de contatos.");
      }
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const debouncedFetch = debounce((value) => {
    if (value.length >= 3) {
      fetchContactLists(value);
    } else {
      setLists([]);
    }
  }, 300);

  const handleSearchChange = (event, value) => {
    setSearch(value);
    debouncedFetch(value);
  };

  const handleSelectList = () => {
    if (selectedListId) {
      onSelect(selectedListId);
      handleClose();
    } else {
      toast.info(i18n.t("contactLists.noSelection"));
    }
  };

  const handleAddNewList = async () => {
    if (!search || search.trim() === "") {
      toast.warn(i18n.t("contactLists.noInformed"));
      return;
    }
    if (search.length < 3) {
      toast.warn(i18n.t("contactLists.searchHint"));
      return;
    }

    try {
      const { data } = await api.post("/contact-lists", {
        name: search.toUpperCase(),
      });
      setLists([...lists, data]);
      setSelectedListId(data.id);
      fetchStatsCampaigns();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    setSearch("");
    setSelectedListId(null);
    onClose();
  };

  return (
    <>
      <ConfirmationModal
        title={`${i18n.t("contactLists.title")}`}
        open={confirmCreate}
        onClose={() => setConfirmCreate(false)}
        onConfirm={handleAddNewList}
      >
        {`${i18n.t("contactLists.newListPrompt")}${search.toUpperCase()}?`}
      </ConfirmationModal>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>{i18n.t("contactLists.title")}</DialogTitle>
        <DialogContent>
          <Tooltip title={i18n.t("contactLists.searchHint")} arrow>
            <div>
              <Autocomplete
                freeSolo
                options={lists}
                getOptionLabel={(option) => option.name}
                value={lists.find((list) => list.id === selectedListId) || null}
                onChange={(event, newValue) => {
                  setSelectedListId(newValue ? newValue.id : null);
                }}
                onInputChange={handleSearchChange}
                renderInput={(params) => (
                  <Tooltip title={i18n.t("contactLists.searchHint")} arrow>
                    <TextField
                      {...params}
                      label={i18n.t("contactLists.search")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Tooltip>
                )}
              />
            </div>
          </Tooltip>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {i18n.t("contactLists.buttons.cancel")}
          </Button>
          <Button
            onClick={() => {
              if (search.trim() === "") {
                toast.warn(i18n.t("contactLists.noInformed"));
                return;
              }

              if (statsCampaigns.stats.groupsCampaigns > 0)
                setConfirmCreate(true);
              else {
                toast.warn('Você atingiu o limite de números de listas de contatos no seu plano!');
                return;
              } 
            }}
            color="primary"
            variant="contained"
          >
            {i18n.t("contactLists.buttons.add")}
          </Button>
          <Button
            onClick={handleSelectList}
            color="primary"
            variant="contained"
          >
            {i18n.t("contactLists.buttons.select")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactListSelectModal;
