import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, Grid, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const ScheduleSchema = Yup.object().shape({
	body: Yup.string()
		.min(5, "Mensagem muito curta")
		.required("Obrigatório"),
	contactId: Yup.number().required("Obrigatório"),
	sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);

	const initialState = {
		body: "",
		contactId: "",
		sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
		sentAt: ""
	};

	const initialContact = {
		id: "",
		name: ""
	}

	const [schedule, setSchedule] = useState(initialState);
	const [currentContact, setCurrentContact] = useState(initialContact);
	const [contacts, setContacts] = useState([initialContact]);

	const [confirmationOpen, setConfirmationOpen] = useState(false);	
	const [fileDelete, setFileDelete] = useState(null); 
	const [attachments, setAttachments] = useState([]);
	const [fileNames, setFileNames] = useState([]); 
	const attachmentFile = useRef(null);	

	useEffect(() => {
		if (contactId && contacts.length) {
			const contact = contacts.find(c => c.id === contactId);
			if (contact) {
				setCurrentContact(contact);
			}
		}
	}, [contactId, contacts]);

	useEffect(() => {
		const { companyId } = user;
		if (open) {
			try {
				(async () => {
					const { data: contactList } = await api.get('/contacts/list', { params: { companyId: companyId } });
					let customList = contactList.map((c) => ({id: c.id, name: c.name}));
					if (isArray(customList)) {
						setContacts([{id: "", name: ""}, ...customList]);
					}
					if (contactId) {
						setSchedule(prevState => {
							return { ...prevState, contactId }
						});
					} 

					if (!scheduleId) return;

					const { data } = await api.get(`/schedules/${scheduleId}`);
					setSchedule(prevState => {
						return { ...prevState, ...data, sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm') };
					}); 

					setCurrentContact(data.contact);
 
					if (data.mediaName && JSON.parse(data.mediaName).length > 0) {
						const originalNames = JSON.parse(data.mediaName).map(item => item.originalName);
						setFileNames(originalNames);
					  } 					
				})()
			} catch (err) {
				toastError(err);
			}
		}
	}, [scheduleId, contactId, open, user]);

	const handleClose = () => {
		setSchedule(initialState);
		setAttachments([]);
		setFileNames([]);
		setFileDelete(null);		
		onClose();		
	};

	const typeMediaUrl = (nomeDoArquivo) => {
		const extensao = nomeDoArquivo.split('.').pop().toLowerCase();
	
		const tiposDeMidiaPorExtensao = {
		  'jpg': 'Imagem', 'jpeg': 'Imagem', 'png': 'Imagem', 'gif': 'Imagem',
		  'mp4': 'Vídeo', 'avi': 'Vídeo', 'mkv': 'Vídeo', 'mov': 'Vídeo', 'mpeg': 'Vídeo',
		  'mp3': 'Áudio', 'ogg': 'Áudio', 'wav': 'Áudio', 
		  'doc': 'Doc', 'docx': 'Doc', 'pdf': 'Doc', 'txt': 'Doc', 'rtf': 'Doc', 'ppt': 'Doc', 'pptx': 'Doc',
		  'xls': 'Doc', 'xlsx': 'Doc', 'csv': 'Doc', 'odt': 'Doc', 'ods': 'Doc', 'odp': 'Doc',
		};
	 
		return tiposDeMidiaPorExtensao[extensao] || '';
	  };
	
	  const isFileNameDuplicate = (fileName) => {
		return attachments.some((file) => file.name === fileName);
	  };
	
	  const handleAttachmentFile = (e) => {
		const file = e.target.files[0];
		const forbiddenFileTypes = [
		  ".jsx", ".js", ".ts", ".dll", ".sys", ".php", ".exe", ".bin", ".cab", ".bat", ".cmd", ".sh",
		  ".htm", ".html"
		];
	  
		if (file) {
		  const fileExtension = `.${file.name.slice(file.name.lastIndexOf(".") + 1)}`; 
		  if (
			forbiddenFileTypes.includes(fileExtension) || 
			!["Vídeo", "Áudio", "Imagem", "Doc"].includes(typeMediaUrl(file.name))
		  ) {
			toast.error(
			  "Tipo de arquivo não permitido. Por favor, selecione um arquivo de áudio/vídeo/imagem/documento.",
			  { autoClose: 5000 }
			);
			return;
		  }
	   
		  const maxSize = 5 * 1024 * 1024; // 5 MB em bytes
		  if (file.size > maxSize) {
			toast.warn(
			  "O tamanho do arquivo excede 5 MB. Por favor, selecione um arquivo menor.",
			  { autoClose: 4000 }
			);
			return;
		  }
	   
		  // Verificar se o arquivo já existe na lista de anexos
		  if (!isFileNameDuplicate(file.name)) {
			setAttachments((prevAttachments) => [...prevAttachments, file]);
			setFileNames((prevFileNames) => [...prevFileNames, file.name]);
		  } else {
			toast.warn(
			  "Um arquivo com este nome já foi anexado. Por favor, selecione outro arquivo.",
			  { autoClose: 5000 }
			);
		  }      
		}
	  };
	  
	const handleSaveSchedule = async values => {
		const scheduleData = { ...values, userId: user.id };

		const formData = new FormData();  

		if (attachments.length > 0) {      
		  values.isMedia = fileNames.length > 0;
		  values.mediaName = fileNames;      
		  values.mediaPath = attachments.map((file) => file.name);
		  formData.append("typeArch", "schedule");
	  
		  attachments.forEach((file) => {
			formData.append("file", file);
		  });
		}
				
		try { 
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, scheduleData);
			} else {
				const { data } = await api.post("/schedules", scheduleData);
				scheduleId = data.id;
			}

			if (attachments.length > 0) {
				await api.post(`/schedules/${scheduleId}/media-upload`, formData);
			}
					  
			toast.success(i18n.t("scheduleModal.success"));
			if (typeof reload == 'function') {
				reload();
			}
			if (contactId) {
				if (typeof cleanContact === 'function') {
					cleanContact();
					history.push('/schedules');
				}
			}
		} catch (err) {
			toastError(err);
		}
		setCurrentContact(initialContact);
		setSchedule(initialState);
		handleClose();
	};

	const deleteMedia = async (index) => { 
		const parsedMediaName = JSON.parse(schedule.mediaName || '[]');  
		let newFileNames = [...parsedMediaName]; 
		let fileNameToDelete = newFileNames[index]?.originalName;
		
		if (!fileNameToDelete) {
		  let localFileNames = [...fileNames];
		  const fileNameToDeleteLocal = localFileNames[index];
		  localFileNames.splice(index, 1);
		  setFileNames(localFileNames);
		  setAttachments(prevAttachments => prevAttachments.filter(file => file.name !== fileNameToDeleteLocal));
		}
	  
		newFileNames = newFileNames.filter(file => file.originalName !== fileNameToDelete);
		
		if (fileNameToDelete) {
		  const fileIndex = parsedMediaName.findIndex(file => file.originalName === fileNameToDelete);
	  
		  if (fileIndex !== -1) { 
			parsedMediaName.splice(fileIndex, 1);
	  
			await api.delete(`/schedules/${schedule.id}/media-upload`, 
			  { data: { originalName: fileNameToDelete } });
	  
			setFileNames(newFileNames.map(item => item.originalName));
			schedule.mediaName = JSON.stringify(parsedMediaName);
			toast.info('Arquivo removido com sucesso!');
	  
			if (typeof reload === "function") {
			  reload();
			}
		  }
		}
	   
		setFileDelete(null);
	  };  
	  
	return (
		<div className={classes.root}>
			<ConfirmationModal
				title={i18n.t("schedules.confirmationModal.deleteTitle")}
				open={confirmationOpen && fileDelete !== null}
				onClose={() => setConfirmationOpen(false)}
				onConfirm={() => deleteMedia(fileDelete)}
			>
				{i18n.t("schedules.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
				</DialogTitle>
				<div style={{ display: "none" }}>
					<input
						type="file"
						accept="*"
						ref={attachmentFile} 
						onChange={e => handleAttachmentFile(e)}
					/>
				</div>
				<Formik
					initialValues={schedule}
					enableReinitialize={true}
					validationSchema={ScheduleSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveSchedule(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<Autocomplete
											fullWidth
											value={currentContact}
											options={contacts}
											onChange={(e, contact) => {
												const contactId = contact ? contact.id : '';
												setSchedule({ ...schedule, contactId });
												setCurrentContact(contact ? contact : initialContact);
											}}
											getOptionLabel={(option) => option.name}
											getOptionSelected={(option, value) => {
												return value.id === option.id
											}}
											renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
										/>
									</FormControl>
								</div>
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										rows={9}
										multiline={true}
										label={i18n.t("scheduleModal.form.body")}
										name="body"
										error={touched.body && Boolean(errors.body)}
										helperText={touched.body && errors.body}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("scheduleModal.form.sendAt")}
										type="datetime-local"
										name="sendAt"
										InputLabelProps={{
										  shrink: true,
										}}
										error={touched.sendAt && Boolean(errors.sendAt)}
										helperText={touched.sendAt && errors.sendAt}
										variant="outlined"
										fullWidth
									/>
								</div>
								{(fileNames.length > 0) && (
									fileNames.map((file, index) => (
										<Grid xs={12} item key={index}>
											<Button startIcon={<AttachFileIcon />}>
												{typeof fileNames[index] === 'string' ? fileNames[index] : 'Nome de Arquivo Inválido'}
											</Button>
											<IconButton
												onClick={() => {
												setFileDelete(index);
												setConfirmationOpen(true); 
												}}
												color="secondary"
											>
												<DeleteOutlineIcon color="secondary" />
											</IconButton>
										</Grid>
									))
								)}
							</DialogContent>
							<DialogActions> 
								<Tooltip title="No máximo 3 arquivos de 5 MB cada." arrow>
									<Button
										color="secondary"
										onClick={() => attachmentFile.current.click()}
										disabled={isSubmitting || fileNames.length >= 3}
										variant="outlined"
									>
										{i18n.t("quickMessages.dialog.buttons.attach")}
									</Button>
								</Tooltip> 
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>
								{ (schedule.sentAt === null || schedule.sentAt === "") && (
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{scheduleId
											? `${i18n.t("scheduleModal.buttons.okEdit")}`
											: `${i18n.t("scheduleModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								)}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ScheduleModal;