import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormControl,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableContainer, 
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OnlyForSuperUser from "../OnlyForSuperUser";
import { AuthContext } from "../../context/Auth/AuthContext.js";
import toastError from "../../errors/toastError.js";
import MainHeader from "../MainHeader/index.js";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper/index.js";
import api from "../../services/api.js";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n.js";
import TableRowSkeleton from "../TableRowSkeleton/index.js";
import { Block, CheckCircle } from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";
import ButtonWithSpinner from "../ButtonWithSpinner/index.js";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  hideOnSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default function ReportsManager(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [time, setTime] = useState(10);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFilterCompanies = useCallback(async () => {
    if (!time) {
      toast.warn("Informe o tempo para pesquisar os clientes!");
      return;
    }

    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get(`/companies/listOnline/${time}`);
        setCompanies(response.data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [time]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchData = async () => {
        try {
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  function renderFilterCompaniesOnline() {
    return (
      <MainHeader>
        <MainHeaderButtonsWrapper>
          <FormControl
            margin="dense"
            variant="outlined"
            style={{ marginRight: 0 }}
          >
            <TextField
              label="Tempo em minuto(s)"
              type="number"
              inputProps={{ min: 0 }}
              value={time}
              variant="outlined"
              margin="dense"
              onChange={(e) => setTime(e.target.value)}
            />
          </FormControl>
          <FormControl
            margin="dense"
            variant="outlined"
            style={{ marginRight: 0, marginTop: 15 }}
          >
            <ButtonWithSpinner
              variant="contained"
              color="primary"
              onClick={handleFilterCompanies}
            >
              {"Filtrar"}
            </ButtonWithSpinner>
          </FormControl>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {i18n.t("companies.table.id")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.status")}
                  </TableCell>
                  <TableCell align="left">
                    {i18n.t("companies.table.name")}
                  </TableCell>
                  <TableCell>{i18n.t("companies.table.vat")}</TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.phone")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("companies.table.email")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell style={{ paddingRight: 0 }}>
                      {company.id}
                    </TableCell>
                    <TableCell align="center" style={{ width: "1%" }}>
                      {company.status ? (
                        <CheckCircle
                          style={{ color: green[500] }}
                          titleAccess={`Empresa ativa`}
                        />
                      ) : (
                        <Block
                          style={{ color: red[500] }}
                          titleAccess={`Empresa inativa`}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">{company.name}</TableCell>
                    <TableCell align="center">{company.vat}</TableCell>
                    <TableCell align="center">{company.phone}</TableCell>
                    <TableCell align="center">{company.email}</TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={6} />}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="left">
                    {i18n.t("table.totalRecords") +
                      (companies ? companies?.length : 0)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </MainHeaderButtonsWrapper>
      </MainHeader>
    );
  }

  return (
    <>
      <Grid spacing={3} container>
        <Grid xs={12} sm={6} md={4} item>
          {/* Placeholder for additional content */}
        </Grid>
      </Grid>

      <OnlyForSuperUser
        user={user}
        yes={() => (
          <Accordion
            className={classes.root}
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                Clientes online
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", flexWrap: "wrap" }}>
              {renderFilterCompaniesOnline()}
            </AccordionDetails>
          </Accordion>
        )}
      />
    </>
  );
}
