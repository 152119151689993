import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, 
	// createTheme, ThemeProvider 
} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
// import { green } from '@material-ui/core/colors';
import NewTicketModal from "../NewTicketModal";


const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));
 
const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles(); 
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);	
	const [reopenTicketModalOpen, setReopenTicketModalOpen] = useState(false);

	// const customTheme = createTheme({
	// 	palette: {
	// 	  	primary: green,
	// 	}
	// });

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true); 
		const queueId = e && e.target ? e.target.value : e; // Verifica se é um evento sintético e obtém o valor apropriado
		try { 
			await api.put(`/tickets/${ticket.id}`, {
				queueId: queueId,
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
				window.location.reload();
			} else {
				setCurrentTicket({ id: null, code: null })
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleReopenTicketModalOpen = () => {
		setReopenTicketModalOpen(true);
	  };
	  

	return ( 
		<div className={classes.actionButtons}>
			{reopenTicketModalOpen &&
				(<NewTicketModal
					modalOpen={true}
					initialContact={ticket.contact}
					isReopen={true}
					onClose={(value) => {
						setReopenTicketModalOpen(false); 
						if (value && value !== null)
							handleUpdateTicketStatus(value, "open", user?.id);
					}} 
				/>	
			)}		
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />} 
					variant="contained"
					color="primary"
					onClick={() => handleReopenTicketModalOpen()}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "pending", null)}>
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>
					{/* <ThemeProvider theme={customTheme}>
						<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
							<IconButton onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)} color="primary">
								<CheckCircleIcon />
							</IconButton>
						</Tooltip>
					</ThemeProvider> */}
					{/* <ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />} 
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner> */}
					<ButtonWithSpinner
						loading={loading} 
						variant="contained"
						color="primary"
						isRed={true}
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading} 
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div> 
	);
};

export default TicketActionButtonsCustom;
