import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { AuthContext } from "../../context/Auth/AuthContext";

import { Button, Divider, } from "@material-ui/core";
import NewTicketModal from "../../components/NewTicketModal";
import ContactModal from "../../components/ContactModal";

const VcardPreview = ({ contact, numbers, ticket }) => {
    const history = useHistory();
    // eslint-disable-next-line
    const currentTicket = ticket;
    const { user } = useContext(AuthContext); 
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false); 
    const [contactModalOpen, setContactModalOpen] = useState(false);

    const [contactData, setContactData] = useState({
        name: "",
        number: 0,
        email: ""
    }); 
    
    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });
 
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async() => {
                try {
                    const searchParam = numbers.replace(/\D/g, "");
                    const pageNumber = 1;
                    let { data } = await api.get("/contacts/", {
                      params: { searchParam, pageNumber },
                    });        
                    
                    if (data.contacts.length > 0) { 
                        setContact(data.contacts[0]);  
                    } else {
                        // let contactObj = {
                        //     name: contact,
                        //     number: numbers.replace(/\D/g, ""),
                        //     email: ""
                        // }
                        // data = await api.post("/contact", contactObj);
                        // setContact(data); 
                    }
                } catch (err) { 
                  toastError(err); 
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    // eslint-disable-next-line
    const handleNewChat = async() => {
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: selectedContact.id,
                userId: user.id,
                status: "open",
            }); 
            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    }
     
    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
          history.push(`/tickets/${ticket.uuid}`);
        }
      };
      
  const setNewContact = (contact, numbers) => {
    setContactModalOpen(false);
    const newContactData = {name: contact, number: numbers.replace(/\D/g, ""), email: ""}
    setContactData(newContactData);
    setContactModalOpen(true);
  };   
  
  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };
  
  const handleSaveContactModal = (contactSaved) => { 
    //history.push(`/tickets/${currentTicket.uuid}`);    
    window.location.reload();
  };
  
    return (
		<>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={selectedContact}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            ></NewTicketModal>
          <ContactModal
            open={contactModalOpen}
            onClose={handleCloseContactModal} 
            onSave={(contactSaved) => {
                handleSaveContactModal(contactSaved);
            }}
            aria-labelledby="form-dialog-title"
            contactId={null}
            initialValues={contactData}
          ></ContactModal>
          
			<div style={{
				minWidth: "250px",
			}}>
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<Avatar src={selectedContact.profilePicUrl} />
					</Grid>
					<Grid item xs={9}>
					    {selectedContact.number === 0 ?
					    <Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
							{contact}
						</Typography> : null}
						
						<Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
							{(selectedContact && selectedContact.name) ? selectedContact.name : 
							    (selectedContact.number > 0 ? selectedContact.number : numbers)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider />
						<Button
							fullWidth
							color="primary"
							//onClick={handleNewChat}
                            onClick={ 
                                selectedContact.number === 0
                                  ? () => setNewContact(contact, numbers)
                                  : () => setNewTicketModalOpen(true)
                              }
				// 			disabled={!selectedContact.number}
						>
						    {selectedContact.number > 0 ? "Conversar" : "Salvar contato"}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	);

};

export default VcardPreview;