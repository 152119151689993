import React, { useContext, useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n"; 
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import ConfirmationModal from "../ConfirmationModal"; 
import IOSSwitch from "../IOSSwitch";
 
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },
  btnWrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const QuickMessageSchema = Yup.object().shape({
  shortcode: Yup.string().required("Obrigatório"),
  message: Yup.string().required("Obrigatório")
});

const QuickMessageDialog = ({ open, onClose, quickMessageId, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const messageInputRef = useRef();

  const initialState = {
    shortcode: "",
    message: "",
    toAll: false
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [fileDelete, setFileDelete] = useState(null);
  const [quickMessage, setQuickMessage] = useState(initialState);
  const [attachments, setAttachments] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [toAll, setToAll] = useState(false);
  const attachmentFile = useRef(null);

  useEffect(() => {
    try {
      (async () => {
        if (!quickMessageId) return;

        const { data } = await api.get(`/quick-messages/${quickMessageId}`); 
        setQuickMessage(prevState => {
          return { ...prevState, ...data };
        });
        setToAll(data.toAll); 
 
        if (data.mediaName && JSON.parse(data.mediaName).length > 0) {
          const originalNames = JSON.parse(data.mediaName).map(item => item.originalName);
          setFileNames(originalNames);
        } 
      })();
    } catch (err) {
      toastError(err);
    }
  }, [quickMessageId, open]);
 
  const handleClose = () => {
    setQuickMessage(initialState);
    setAttachments([]);
    setFileNames([]);
    setFileDelete(null);
    setToAll(false);
    onClose();
  };

  const typeMediaUrl = (nomeDoArquivo) => {
    const extensao = nomeDoArquivo.split('.').pop().toLowerCase();

    const tiposDeMidiaPorExtensao = {
      'jpg': 'Imagem', 'jpeg': 'Imagem', 'png': 'Imagem', 'gif': 'Imagem',
      'mp4': 'Vídeo', 'avi': 'Vídeo', 'mkv': 'Vídeo', 'mov': 'Vídeo', 'mpeg': 'Vídeo',
      'mp3': 'Áudio', 'ogg': 'Áudio', 'wav': 'Áudio', 
      'doc': 'Doc', 'docx': 'Doc', 'pdf': 'Doc', 'txt': 'Doc', 'rtf': 'Doc', 'ppt': 'Doc', 'pptx': 'Doc',
      'xls': 'Doc', 'xlsx': 'Doc', 'csv': 'Doc', 'odt': 'Doc', 'ods': 'Doc', 'odp': 'Doc',
    };
 
    return tiposDeMidiaPorExtensao[extensao] || '';
  };

  const isFileNameDuplicate = (fileName) => {
    return attachments.some((file) => file.name === fileName);
  };

  const handleAttachmentFile = (e) => {
    const file = e.target.files[0];
    const forbiddenFileTypes = [
      ".jsx", ".js", ".ts", ".dll", ".sys", ".php", ".exe", ".bin", ".cab", ".bat", ".cmd", ".sh",
      ".htm", ".html"
    ];
  
    if (file) {
      const fileExtension = `.${file.name.slice(file.name.lastIndexOf(".") + 1)}`; 
      if (
        forbiddenFileTypes.includes(fileExtension) || 
        !["Vídeo", "Áudio", "Imagem", "Doc"].includes(typeMediaUrl(file.name))
      ) {
        toast.error(
          "Tipo de arquivo não permitido. Por favor, selecione um arquivo de áudio/vídeo/imagem/documento.",
          { autoClose: 5000 }
        );
        return;
      }
   
      const maxSize = 5 * 1024 * 1024; // 5 MB em bytes
      if (file.size > maxSize) {
        toast.warn(
          "O tamanho do arquivo excede 5 MB. Por favor, selecione um arquivo menor.",
          { autoClose: 4000 }
        );
        return;
      }
   
      if (!isFileNameDuplicate(file.name)) {
        setAttachments((prevAttachments) => [...prevAttachments, file]);
        setFileNames((prevFileNames) => [...prevFileNames, file.name]);
      } else {
        toast.warn(
          "Um arquivo com este nome já foi anexado. Por favor, selecione outro arquivo.",
          { autoClose: 5000 }
        );
      }      
    }
  };
  
  const handleSaveQuickMessage = async (values) => { 
    values.isMedia = false; 
    values.toAll = toAll;

    const formData = new FormData();  

    if (attachments.length > 0) {      
      values.isMedia = fileNames.length > 0;
    //   values.mediaName = fileNames;      
    //   values.mediaPath = attachments.map((file) => file.name);
      formData.append("typeArch", "quickMessage");
  
      attachments.forEach((file) => {
        formData.append("file", file);
      });
    }
    
    try {
      if (quickMessageId) {
        await api.put(`/quick-messages/${quickMessageId}`, values);
      } else {
        const { data } = await api.post("/quick-messages", values);
        quickMessageId = data.id;
      }
  
      if (attachments.length > 0) {
        await api.post(`/quick-messages/${quickMessageId}/media-upload`, formData);
      }
  
      toast.info(`Resposta ${quickMessageId ? 'atualizada' : 'adicionada'} com sucesso!`);
  
      if (typeof reload === "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
  
    handleClose();
  };
 
  const deleteMedia = async (index) => { 
    const parsedMediaName = JSON.parse(quickMessage.mediaName || '[]');  
    let newFileNames = [...parsedMediaName]; 
    let fileNameToDelete = newFileNames[index]?.originalName;
    
    if (!fileNameToDelete) {
      let localFileNames = [...fileNames];
      const fileNameToDeleteLocal = localFileNames[index];
      localFileNames.splice(index, 1);
      setFileNames(localFileNames);
      setAttachments(prevAttachments => prevAttachments.filter(file => file.name !== fileNameToDeleteLocal));
    }
  
    newFileNames = newFileNames.filter(file => file.originalName !== fileNameToDelete);
    
    if (fileNameToDelete) {
      const fileIndex = parsedMediaName.findIndex(file => file.originalName === fileNameToDelete);
  
      if (fileIndex !== -1) { 
        parsedMediaName.splice(fileIndex, 1);
  
        await api.delete(`/quick-messages/${quickMessage.id}/media-upload`, 
          { data: { originalName: fileNameToDelete } });
  
        setFileNames(newFileNames.map(item => item.originalName));
        quickMessage.mediaName = JSON.stringify(parsedMediaName);
        toast.info('Arquivo removido com sucesso!');
  
        if (typeof reload === "function") {
          reload();
        }
      }
    }
   
    setFileDelete(null);
  };  
  
  const handleToAllChange = async (e) => {
    const { checked } = e.target; 
    setToAll(checked); 
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("quickMessages.confirmationModal.deleteTitle")}
        open={confirmationOpen && fileDelete !== null}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => deleteMedia(fileDelete)}
      >
        {i18n.t("quickMessages.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickMessageId
            ? `${i18n.t("quickMessages.dialog.edit")}`
            : `${i18n.t("quickMessages.dialog.add")}`}
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept="*"
            ref={attachmentFile} 
            onChange={e => handleAttachmentFile(e)}
          />
        </div>
        <Formik
          initialValues={quickMessage}
          enableReinitialize={true}
          validationSchema={QuickMessageSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickMessage(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className={classes.mainPaper}>
              <DialogContent dividers className={classes.mainPaper}>
                <div className={classes.multFieldLine}>
                  <Grid spacing={2} container>
                    <Grid xs={12} sm={6} md={6} item>
                      <Field
                        as={TextField}
                        label={i18n.t("quickMessages.dialog.form.shortcode")}
                        name="shortcode"
                        error={touched.shortcode && Boolean(errors.shortcode)}
                        helperText={touched.shortcode && errors.shortcode}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    {(profile === "admin" || profile === "supervisor") && (
                      <Grid xs={12} sm={6} md={6} item>
                        <FormControl className={classes.selectContainer}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={toAll}
                                onChange={async e => {
                                  handleToAllChange(e);
                                }} 
                              />
                            }
                            label={i18n.t("quickMessages.dialog.form.toAll")}
                          />
                          <FormHelperText>
                            {toAll
                              ? "Disponível para todos."
                              : "Somente para mim."}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid xs={12} item>
                      <Field
                        as={TextField}
                        label={i18n.t("quickMessages.dialog.form.message")}
                        id="message"
                        name="message"
                        inputRef={messageInputRef}
                        error={touched.message && Boolean(errors.message)}
                        variant="outlined"
                        margin="dense"
                        multiline={true}
                        rows={7}
                        fullWidth 
                      />
                    </Grid>
                    {(fileNames.length > 0) && (
                      fileNames.map((file, index) => (
                        <Grid xs={12} item key={index}>
                          <Button startIcon={<AttachFileIcon />}>
                            {typeof fileNames[index] === 'string' ? fileNames[index] : 'Nome de Arquivo Inválido'}
                          </Button>
                          <IconButton
                            onClick={() => {
                              setFileDelete(index);
                              setConfirmationOpen(true); 
                            }}
                            color="secondary"
                          >
                            <DeleteOutlineIcon color="secondary" />
                          </IconButton>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions> 
                <Tooltip title="No máximo 3 arquivos de 5 MB cada." arrow>
                  <Button
                    color="secondary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting || fileNames.length >= 3}
                    variant="outlined"
                  >
                    {i18n.t("quickMessages.dialog.buttons.attach")}
                  </Button>
                </Tooltip> 
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickMessages.dialog.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickMessageId
                    ? `${i18n.t("quickMessages.dialog.buttons.edit")}`
                    : `${i18n.t("quickMessages.dialog.buttons.add")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QuickMessageDialog;
