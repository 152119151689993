import React, { useContext, useEffect, useMemo, useReducer, useState } from "react"; 
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// eslint-disable-next-line
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import EventAvailableIcon from "@material-ui/icons/EventAvailable";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { DeviceHubOutlined } from '@material-ui/icons';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const { drawerClose, isShowCaptions } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false); 
  const [showCampaigns, setShowCampaigns] = useState(false); 
  const [showUseApi, setShowUseApi] = useState(false); 

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);   
  const [useIntegration, setUseIntegration] = useState(false);
  const { finder: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      if (user && user.company) {
        const planData = await listPlans(user.company.planId);
        setUseIntegration(planData.useIntegration || false);
      } else 
        setUseIntegration(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId }); 

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
    if (localStorage.getItem("useApi")) {
      setShowUseApi(true);
    }
    
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div onClick={drawerClose}>
      {/* <>
        <Divider />
        {isShowCaptions && (<ListSubheader inset>
            {i18n.t("mainDrawer.listItems.operational")}
          </ListSubheader>
        )}
      </> */}

      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary={isShowCaptions && i18n.t("mainDrawer.listItems.dashboard")} 
            icon={<DashboardOutlinedIcon 
              titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.dashboard") : ''}
            ></DashboardOutlinedIcon>}
          />
        )}
      />

      <Divider />

      <ListItemLink
        to="/tickets"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.tickets") : ''}
        />}
      />

      <ListItemLink
        to="/quick-messages"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<FlashOnIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.quickMessages") : ''}
        />}
      />

      <ListItemLink
        to="/contacts"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.contacts") : ''}
        />}
      />

      <ListItemLink
        to="/schedules"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.schedules") : ''}
        />}
      />

      <ListItemLink
        to="/tags"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.tags") : ''}
        />}
      />

      <ListItemLink
        to="/chats"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.chats")}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <ForumIcon 
              titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.chats") : ''}
            />
          </Badge>
        }
      />

      <ListItemLink
        to="/helps"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpOutlineIcon 
          titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.helps") : ''}
        />}
      /> 

      <Divider />
      {isShowCaptions && (user.profile === 'user') && (
        <ListSubheader inset>
          {i18n.t("mainDrawer.listItems.administration")}
        </ListSubheader>     
      )}     

      <ListItemLink
        to="/connections"
        primary={isShowCaptions && i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
            <SyncAltIcon
              titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.connections") : ''}
            />
          </Badge>
        }
      />                      

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            {isShowCaptions && (<ListSubheader inset>
                {i18n.t("mainDrawer.listItems.campaigns")}
              </ListSubheader>   
            )}         
              {showCampaigns && (
                <> 
                  <ListItemLink
                    to="/campaigns"
                    primary={isShowCaptions && i18n.t("mainDrawer.listItems.campaignsList")}
                    icon={<ListIcon 
                      titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.campaignsList") : ''}
                    />}
                  />
                  <ListItemLink
                    to="/contact-lists" 
                    primary={isShowCaptions && i18n.t("mainDrawer.listItems.campaignsListContacts")}
                    icon={<PeopleIcon 
                      titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.campaignsListContacts") : ''}
                    />}
                  />
                  <ListItemLink
                    to="/campaigns-config"
                    primary={isShowCaptions && i18n.t("mainDrawer.listItems.campaignsSettings")}
                    icon={<SettingsOutlinedIcon 
                      titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.campaignsSettings") : ''}
                    />}
                  />                  
                </>
              )}          


            <Divider />
            {isShowCaptions && (<ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>     
            )}     
       
            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={isShowCaptions && i18n.t("mainDrawer.listItems.annoucements")}
                icon={<AnnouncementIcon 
                  titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.annoucements") : ''}
                />}
              />
            )}
            {(useIntegration && useIntegration === true) && (
              <ListItemLink
                to="/integrations"
                primary={isShowCaptions && i18n.t("mainDrawer.listItems.queueIntegration")}
                icon={<DeviceHubOutlined 
                  titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.queueIntegration") : ''}
                />}
              />
            )}
            <ListItemLink
              to="/queues"
              primary={isShowCaptions && i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon
                titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.queues") : ''}
              />}
            />
            <ListItemLink
              to="/users"
              primary={isShowCaptions && i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon 
                titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.users") : ''}
              />}
            />
            {(user.super || showUseApi ) && (
              <ListItemLink
                to="/apidocs" 
                primary={isShowCaptions && i18n.t("mainDrawer.listItems.messagesAPI")}
                icon={<CodeRoundedIcon
                  titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.messagesAPI") : ''}
                />}
              />
            )}
            <ListItemLink
              to="/invoices"
              primary={isShowCaptions && i18n.t("mainDrawer.listItems.invoices")}
              icon={<LocalAtmIcon 
                titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.invoices") : ''}
              />} 
            />
            <ListItemLink
              to="/settings"
              primary={isShowCaptions && i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon 
                titleAccess={!isShowCaptions ? i18n.t("mainDrawer.listItems.settings") : ''}
              />}
            />
{/*             <ListItemLink
              to="/subscription"
              primary="Assinatura"
              icon={<PaymentIcon />}
              //className={classes.menuItem}
            /> */}
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
