import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Hidden,
	useTheme
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EventAvailable from "@material-ui/icons/EventAvailable";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
 
import logo from "../assets/logotipo.png";
import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";
//import { versionSystem } from "../../package.json";
import moment from "moment";
import api from "../services/api"; 

import ColorModeContext from "../layout/themeContext";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { toast } from "react-toastify";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  labelStyle: {
    fontSize: "14px",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed #2f5604  #0891B2
    color: theme.mode === 'dark' ? "" : "white",
    background: process.env.REACT_APP_COLOR_TOOLBAR || "primary"
  },
  toolbarIcon: {
    backgroundColor: theme.mode === 'dark' ? "" : "white", // Cor barra superior
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", //"space-between",
    padding: "0 8px",
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      height: "48px"
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 20,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden", 
    // backgroundColor: "#0891B2", //#808b8f  // cor de fundo da barra lateral
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11), // largura do menu quando está fechado 7.3
    },
  },
  appBarSpacer: {
    minHeight: "40px",
  },
  content: {
    flex: 1,
    overflow: "auto",
		display: "flex",
		backgroundColor: theme.mode === 'dark' ? "" : "#eee",
		flexDirection: "column",
		// padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "auto", //scroll
		...theme.scrollbarStyles,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",  
    flexDirection: "column",
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "auto", //scroll
    ...theme.scrollbarStyles,
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext); 
  const [userId, setUserId] = useState(null);

	const theme = useTheme();  
  const { colorMode } = useContext(ColorModeContext);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userIdLocal = localStorage.getItem("userId");

    setUserId(userIdLocal);

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userIdLocal) {
        toastError("Sua conta foi acessada em outro dispositivo.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.on(`company-${companyId}-user`, (data) => {
      if (data.action === "update") {
        if (!data.user.status && data.user.id === +userIdLocal) {
          toast.warn(`Seu usuário foi desativado pelo administrador da ${data.user.company.name}. `, { autoClose: 7000 });
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 1000);
        }
      }

      if (data.action === "delete") { 
        if (+data.userId === +userIdLocal) {
          toastError(`Seu usuário foi deletado pelo administrador. `);
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 1000);
        }
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`); 
        setDrawerOpen(data?.drawerMenuOpen ?? true);
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleSaveDrawer = async () => {
    setDrawerOpen(!drawerOpen);
    try { 
      await api.put(`/users/drawer/${userId}`, { drawerMenuOpen: !!(!drawerOpen) }); 
    } catch (err) {
      toastError(err);
    }
  };

	const handleSignatureRenew = () => {
		history.push('/invoices');
	}

	const toggleColorMode = () => {
		colorMode.toggleColorMode();
	}

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    if (theme.mode === 'dark')
      toggleColorMode();
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };
 
  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
      return false;
    } 
    return drawerOpen;
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}> 
          <img src={logo} className={classes.logo} alt="logo" />
          <IconButton onClick={handleSaveDrawer}>
            <ChevronLeftIcon /> 
          </IconButton>
        </div>
        <Divider />
        <List className={classes.containerWithScroll}>
          <MainListItems 
            drawerClose={drawerClose} 
            isShowCaptions={drawerOpen} 
          />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color="primary"
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleSaveDrawer}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon titleAccess="Expandir menu" />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          > 
            <Hidden smDown> 
              {!drawerOpen ? process.env.REACT_APP_NAME + ' - ' : ''} 
              {user.company ? user.company.name : 'Atendimento Centralizado' }  
            </Hidden>
            <Hidden smUp> 
              {user.company ? user.company.name : process.env.REACT_APP_NAME} 
            </Hidden>             
          </Typography>
  
            <label 
              className={`${classes.labelStyle} labelToHide`}
              style={{ display: (!drawerOpen || document.body.offsetWidth > 768) ? 'block' : 'none' }}
            > 
              <Hidden smDown>
                {(user.profile === "admin" && user.company.dueDate) ? 
                  i18n.t("mainDrawer.appBar.user.signature") + 
                  moment(user.company.dueDate).format("DD/MM/YYYY") : null}
              </Hidden>              
            </label> 

            {(user.profile === "admin" && user.company.dueDate) ? 
              <IconButton
                aria-label="Signature overdue"
                aria-controls="menu-appbar"
                onClick={handleSignatureRenew}
                color="inherit"
              >              
                <EventAvailable
                  titleAccess="Validade da sua assinatura"
                />
              </IconButton> : null
            }

            <IconButton 
              edge="start" 
              onClick={toggleColorMode} 
              color="inherit">
              {theme.mode === 'dark' ? 
              <Brightness7Icon 
                titleAccess="Modo ligth"
              /> : 
              <Brightness4Icon 
                titleAccess="Modo dark"
              />}              
            </IconButton>              
          
            {user.id && <NotificationsPopOver />}
            {user.id && <AnnouncementsPopover />}
            {user.id && <ChatPopover />}

          <div>
            <label>              
              <Hidden only={["sm", "xs"]}>
                {user.name}
              </Hidden>              
            </label> 
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              color="inherit"
            >
              <AccountCircle 
                titleAccess="Sua conta"
              >
              </AccountCircle> 
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}
      >
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
