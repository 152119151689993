import React, { useState, useEffect } from "react";
// import * as Yup from "yup";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  // MenuItem,
  // Menu,
  IconButton,
  Tooltip ,
  useMediaQuery,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import ItemSelect from "../ItemSelect";
import { Autorenew, FileCopy, //MoreVert 
} from "@material-ui/icons";
import useSettings from "../../hooks/useSettings"; 

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

// const SessionSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(2, "Muito curto!")
//     .max(50, "Muito longo!")
//     .required("Obrigatório")
// });

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta",
    timeToTransfer: "", 
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [itemsQueues, setItemsQueues] = useState([]); 
  const [selectedQueueId, setSelectedQueueId] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [menuOpen, setMenuOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [useApi, setUseApi] = useState(false); 

  const { find } = useSettings();
  
  const isMobile = useMediaQuery('(max-width: 600px)'); // Ajuste o valor conforme necessário  

  const handleFieldChange = (fieldName, value) => {
    setWhatsApp(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  };

  useEffect(() => { 
    const fetchSession = async () => {
      const useApiData = await find('useApi'); 
      setUseApi(useApiData.value === 'true'); 
    };
    fetchSession();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSession = async () => {
      setIsSubmitting(false);
      setSelectedQueueIds([]);
      if (!whatsAppId) return;
      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);
        const whatsQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        setSelectedQueueId(data.transferQueueId || null); 

        const itemsQueuesData = [
          { value: 0, name: "Nenhuma" },
          ...data.queues?.map((queue) => ({ value: queue.id, name: queue.name })),
        ];   
        setItemsQueues(itemsQueuesData);        
        
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async values => {
    let idQueueTransf = selectedQueueId; 

    if (values.timeToTransfer === '' || 
      values.timeToTransfer === '0' || 
      values.timeToTransfer === 0 || 
      values.timeToTransfer === null) 
    {
      idQueueTransf = null;
      values.timeToTransfer = null;
    } else 
    if (!(idQueueTransf > 0) || idQueueTransf === null) {
      toast.warn('Informe a fila de transferência automática!');
      return;
    }      
    
    setIsSubmitting(true);
    
    const whatsappData = { 
      ...values, 
      queueIds: selectedQueueIds, 
      transferQueueId: idQueueTransf, 
    };
    
    delete whatsappData["queues"];
    delete whatsappData["session"]; 
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedQueueId(null);
    setWhatsApp(initialState);
  };

  // const handleOptionsMenu = event => {
  //   setAnchorEl(event.currentTarget);
  //   setMenuOpen(true);
  // };

  const handleCloseOptionsMenu = () => {
    // setMenuOpen(false);
  };

  function generateRandomToken(length, setInField) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
    if (setInField === true)
      handleFieldChange("token", token);
    return token;
  }

  const handleMessagesDefault = () => {
    handleCloseOptionsMenu();

    if (whatsApp.name === "") {
      toast.warn("Em nome, defina o nome da sua empresa de modo temporário!");
      return;
    }

    const temAsteristic = whatsApp.name.includes("*");

    const greetingMessage = `{Olá|Oi|Opa|Opa jóia|Olá, tudo bem?|Oi, tudo jóia?} {{ms}} {{name}}! 

Bem vindo(a) à ${whatsApp.name}.

Por favor, informe o departamento correspondente que deseja atendimento.`;
    const complationMessage = `Atendimento *finalizado*.
O número do protocolo é: *{{protocol}}*

A ${whatsApp.name} agradece seu contato!`;
    const outOfHoursMessage = `Estamos *fora do horário* de expediente, em breve *retornaremos* sua mensagem!`;
    const ratingMessage = `"Por favor, *avalie nosso atendimento*. Sua opinião é importante para nós!"`;

    handleFieldChange("greetingMessage", greetingMessage);
    handleFieldChange("complationMessage", complationMessage);
    handleFieldChange("outOfHoursMessage", outOfHoursMessage);
    handleFieldChange("ratingMessage", ratingMessage);

    if (whatsApp.token === "" && useApi) {
      const token = generateRandomToken(64, false);
      handleFieldChange("token", token);
    }

    if (temAsteristic && temAsteristic === true)
      whatsApp.name = whatsApp.name.replaceAll("*", "");

    toast.info("Mensagens definidas com sucesso!");
  };

  const handleSubmit = async event => {

    if (whatsApp.timeToTransfer > 0 && selectedQueueId && selectedQueueIds.length > 0 && !selectedQueueIds.includes(selectedQueueId)) {
      toast.warn(
        "A fila para auto-transferência precisa está entre as filas que a conexão irá atender!",
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (!(whatsApp.timeToTransfer > 0))
      setSelectedQueueId(null); 
      
    event.preventDefault();
    if (whatsApp.name === "") {
      toast.warn(
        "Campo nome é obrigatório. Algo que identifique seu número de WhatsApp."
      );
      return;
    }
    try {
      await handleSaveWhatsApp(whatsApp);
    } catch (error) {
      toastError(error);
    }
  };

  const copiarConteudo = () => {
    const campoTexto = document.getElementById("token");  
  
    campoTexto.select();
    document.execCommand("copy");
  
    navigator.clipboard.writeText(campoTexto.value)
      .then(() => {
        toast.info('Token Bearer copiado com sucesso!');
      })
      .catch((err) => {
        toastError('Erro ao copiar o Token Bearer: '+ err);
      });
  
    // Desselecione o texto após a cópia (opcional)
    window.getSelection().removeAllRanges();
  };  

  const handleQueueSelectChange = (selectedIds) => {
    setSelectedQueueId(selectedIds);    
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <DialogTitle>
            {whatsAppId
              ? i18n.t("whatsappModal.title.edit")
              : i18n.t("whatsappModal.title.add")}
          </DialogTitle>

          {/* <IconButton
            onClick={handleOptionsMenu}
            style={{ marginLeft: "auto" }}
            disabled={isSubmitting}
          >
            <MoreVert />
          </IconButton> */}
        </div>
        {/* <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={menuOpen}
          onClose={handleCloseOptionsMenu}
        >
          <MenuItem onClick={handleMessagesDefault}>
            Gerar mensagens padrão
          </MenuItem>
        </Menu> */}

        <form onSubmit={handleSubmit} className={classes.mainPaper}>
          <DialogContent dividers>
            <div className={classes.multFieldLine}>
              <Grid spacing={2} container>
                <Grid item>
                  <TextField
                    label={i18n.t("whatsappModal.form.name")}
                    autoFocus
                    name="name"
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    value={whatsApp.name}
                    onChange={event =>
                      handleFieldChange("name", event.target.value)
                    }
                    helperText="Algo que faça lembrar o número dessa conexão."
                  />
                </Grid>
                <Grid style={{ paddingTop: 15 }} item>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="isDefault"
                        disabled={isSubmitting}
                        checked={whatsApp.isDefault}
                        onChange={event =>
                          handleFieldChange("isDefault", event.target.checked)
                        }
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.greetingMessage")}
                type="greetingMessage"
                multiline
                rows={5}
                fullWidth
                name="greetingMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.greetingMessage}
                onChange={event =>
                  handleFieldChange("greetingMessage", event.target.value)
                }
                helperText="É possível usar o spintax, ex.: {Olá|Oi|Hello}"
              />
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.complationMessage")}
                type="complationMessage"
                multiline
                rows={4}
                fullWidth
                name="complationMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.complationMessage}
                onChange={event =>
                  handleFieldChange("complationMessage", event.target.value)
                }
              />
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.outOfHoursMessage")}
                type="outOfHoursMessage"
                multiline
                rows={3}
                fullWidth
                name="outOfHoursMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.outOfHoursMessage}
                onChange={event =>
                  handleFieldChange("outOfHoursMessage", event.target.value)
                }
              />
            </div>
            <div>
              <TextField
                label={i18n.t("queueModal.form.ratingMessage")}
                type="ratingMessage"
                multiline
                rows={2}
                fullWidth
                name="ratingMessage"
                variant="outlined"
                margin="dense"
                value={whatsApp.ratingMessage}
                onChange={event =>
                  handleFieldChange("ratingMessage", event.target.value)
                }
              />
            </div>
            {useApi && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  label={i18n.t("queueModal.form.token")}
                  type="token"
                  fullWidth
                  id="token"
                  name="token"
                  variant="outlined"
                  margin="dense"
                  value={whatsApp.token}
                  onChange={event =>
                    handleFieldChange("token", event.target.value)
                  }
                  disabled
                />
                <Tooltip title="Gerar Token Bearer">
                  <IconButton onClick={() => generateRandomToken(64, true)}>
                    <Autorenew /> 
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copiar token">
                  <IconButton onClick={() => copiarConteudo()}>
                    <FileCopy /> 
                  </IconButton>              
                </Tooltip>
              </div>
            )}
            <QueueSelect
              selectedQueueIds={selectedQueueIds}
              onChange={selectedIds => setSelectedQueueIds(selectedIds)}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2}>
              <Grid item sm={6} >
                <TextField
                  fullWidth
                  type="number" 
                  label="Transferir após x (minutos)"
                  placeholder="Transferir após x (minutos)" 
                  name="timeToTransfer" 
                  value={whatsApp.timeToTransfer ?? ''}
                  onChange={event =>
                    handleFieldChange("timeToTransfer", event.target.value)
                  }
                  variant="outlined"
                  margin="dense" 
                />
              </Grid>

              <Grid item sm={6}>
                <ItemSelect 
                    fullWidth
                    disabled={isSubmitting}
                    title={'Fila de Auto-Transferência'}
                    selectedValues={selectedQueueId || '0'}
                    items={itemsQueues} 
                    onChange={handleQueueSelectChange} 
                    minWidth={!isMobile ? 250 : 200}
                    labelWidth={190} 
                /> 
              </Grid>
            </Grid>				
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("whatsappModal.buttons.cancel")}
            </Button>

            <Button 
              color="primary"
              variant="outlined"
              className={classes.btnWrapper}
              onClick={handleMessagesDefault}
            >
              Gerar mensagens
            </Button>

            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              variant="contained"
              className={classes.btnWrapper}
              onClick={handleSubmit}
            >
              {whatsAppId
                ? i18n.t("whatsappModal.buttons.okEdit")
                : i18n.t("whatsappModal.buttons.okAdd")}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
