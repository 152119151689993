import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import {FormControl, IconButton, TableBody, TableFooter, Typography} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import SubscriptionModal from "../../components/SubscriptionModal";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import toastError from "../../errors/toastError";

import moment from "moment";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ItemSelect from "../../components/ItemSelect";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import useCompanies from "../../hooks/useCompanies";
import { useMemo } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const reducer = (state, action) => {
  if (action.type === "LOAD_INVOICES") {
    const invoices = action.payload;
    const newUsers = [];

    invoices.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Invoices = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  // const [searchParam, ] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [invoices, dispatch] = useReducer(reducer, []);
  const [storagePlans, setStoragePlans] = React.useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [invoiceModalOpenUpgrade, setInvoiceModalOpenUpgrade] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');  
  const [itemsCompamies, setItemsCompamies] = useState([]);
  const [planName, setPlanName] = useState('');
  const companyId = localStorage.getItem("companyId"); 
 
  const isMobile = useMediaQuery('(max-width: 600px)'); // Ajuste o valor conforme necessário

  // eslint-disable-next-line
  const itemsStatus = [
    { value: 'open', name: "Não pago"},
    { value: 'paid', name: "Pago"},
    { value: 'all', name: "Todos"},
  ];

  const { listSimple: listCompanies } = useCompanies();
  const { find: getCompany } = useCompanies();

  useEffect(() => {
    async function fetchData() { 
      setSelectedStatus('open');       
      const companiesList = await listCompanies(); 
      const myCompany = await getCompany(companyId); 
      setPlanName(myCompany.plan?.name);       
      if (user.super) {         
        const itemsCompaniesData = [
          { value: 0, name: "Todas" },
          ...companiesList.map((company) => ({ value: company.id, name: company.name })),
        ];   
        setItemsCompamies(itemsCompaniesData);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  const handleItemSelect = (selectedValue) => {
    dispatch({ type: "RESET" });
    setSelectedStatus(selectedValue); 
  };  

  const handleSearchParamSelect = (selectedValue) => {
    dispatch({ type: "RESET" });
    setSearchParam(selectedValue); 
  }; 
 
  const handleOpenInvoiceModal = (invoices) => {
    setStoragePlans(invoices); 
    setSelectedInvoiceId(null);
    setInvoiceModalOpenUpgrade(false);
    setInvoiceModalOpen(true);
  };
 
  const handleCloseInvoiceModal = () => {
    setSelectedInvoiceId(null);
    setInvoiceModalOpen(false);
    setInvoiceModalOpenUpgrade(false);
  };
 
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {

      const fetchInvoices = async () => {
        try {
          let data;
          const selectedStatusSend = selectedStatus === 'all' ? '' : selectedStatus;
          if (user.super) {
            const searchParamSend = searchParam === '0' ? '' : searchParam; 
            const response = await api.get("/invoices/listAll", {
              params: { companyId: searchParamSend, status: selectedStatusSend, pageNumber },
            });
            data = response.data;  
          } else {
            const response = await api.get("/invoices/all", {
              params: { searchParam, status: selectedStatusSend, pageNumber },
            });
            data = response.data;  
          }      
          if (Array.isArray(data)) {
            dispatch({ type: "LOAD_INVOICES", payload: data }); 
            setHasMore(data.hasMore);
            setLoading(false);
          } else {
            console.error("Invalid data format:", data);
            // Lide com o formato de dados inválido de acordo com sua lógica
          }
        } catch (err) {
          toastError(err);
        }
      };
      
      fetchInvoices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, selectedStatus, user.super]);


  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const rowStyle = (record) => {
    const hoje = moment(moment()).format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    var diff = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"));
    var dias = moment.duration(diff).asDays();    
    if (dias < 0 && record.status !== "paid") {
      return { backgroundColor: "#ffbcbc9c" };
    }
  };

  const rowStatus = (record) => {
    const hoje = moment(moment()).format("DD/MM/yyyy");
    const vencimento = moment(record.dueDate).format("DD/MM/yyyy");
    var diff = moment(vencimento, "DD/MM/yyyy").diff(moment(hoje, "DD/MM/yyyy"));
    var dias = moment.duration(diff).asDays();    
    const status = record.status;
    if (status === "paid") {
      return "Pago";
    }
    if (dias < 0) {
      return "Vencido";
    } else {
      return "Em aberto"
    }

  }
 
  const calculateTotalValue = (invoices, filterParam) => {
    if (invoices && invoices.length > 0) {
      if (!filterParam) {
        // Se nenhum filtro for fornecido, somar todos os valores
        return invoices.reduce((total, invoice) => total + invoice.value, 0);
      } else {
        // Se houver um filtro, somar apenas os valores correspondentes ao filtro
        return invoices
          .filter((invoice) => invoice.status === filterParam)
          .reduce((total, invoice) => total + invoice.value, 0);
      }
    }
    return 0;
  };  

  // eslint-disable-next-line
  const totalValueAll = useMemo(() => calculateTotalValue(invoices, null), [invoices]);
  // eslint-disable-next-line
  const totalValueOpen = useMemo(() => calculateTotalValue(invoices, itemsStatus[0].value), [invoices, itemsStatus]);
  // eslint-disable-next-line
  const totalValuePaid = useMemo(() => calculateTotalValue(invoices, itemsStatus[1].value), [invoices, itemsStatus]);
 
  const handleUpgradePlan = (invoices) => {
    setStoragePlans(invoices); 
    setSelectedInvoiceId(null);
    setInvoiceModalOpenUpgrade(true);
    setInvoiceModalOpen(true);
  };
 
  return (
    <MainContainer> 
      <SubscriptionModal
        open={invoiceModalOpen}
        onClose={handleCloseInvoiceModal}
        aria-labelledby="form-dialog-title"
        Invoice={storagePlans}
        invoiceId={selectedInvoiceId}
        mode={invoiceModalOpenUpgrade}
      ></SubscriptionModal> 

      <MainHeader>
        {!isMobile && (<Title>Faturas</Title>)}
        <MainHeaderButtonsWrapper>
          {(!isMobile && ((searchParam && searchParam !== '0') || !user.super)) && (
            <FormControl margin="dense" variant="outlined" style={{ marginRight: 0 }}> 
              <Typography variant="subtitle1" align="right" style={{ marginTop: 15 }}>
                {`Plano atual: `}
                <span style={{ fontWeight: 'bold' }}>{planName}</span>
              </Typography>
            </FormControl>
          )}          
          <FormControl margin="dense" variant="outlined" style={{ marginRight: 0 }}> 
            <ItemSelect 
              title={'Filtrar'}
              selectedValues={selectedStatus || 'open'}
              items={itemsStatus} 
              onChange={handleItemSelect} 
              minWidth={!isMobile ? 200 : 150}
            />      
          </FormControl>          
          {user.super && (
            <FormControl margin="dense" variant="outlined" style={{ marginRight: 0 }}> 
              <ItemSelect 
                title={'Empresa'}
                selectedValues={searchParam || '0'}
                items={itemsCompamies} 
                onChange={handleSearchParamSelect} 
                minWidth={!isMobile ? 250 : 200}
                labelWidth={65} 
              />
            </FormControl>    
          )}  
          <FormControl margin="dense" variant="outlined" style={{ marginRight: 0, marginTop: 15 }}> 
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleUpgradePlan(true)}
            >
              {'Upgrade do plano'}
            </Button> 
          </FormControl>                 
        </MainHeaderButtonsWrapper>    
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {(user.super && (searchParam === '0' || !searchParam)) && (
                <TableCell align="left">Empresa</TableCell>
              )}
              <TableCell align="center">Fatura</TableCell>
              <TableCell align="center">Detalhes</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Vencimento</TableCell>
              <TableCell align="center">Identificador</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {invoices.map((invoices) => (
                <TableRow style={rowStyle(invoices)} key={invoices.id}>
                  {(user.super && (searchParam === '0' || !searchParam)) && (
                    <TableCell style={{ fontWeight: 'bold' }} align="left">{invoices.company.name}</TableCell>
                  )}
                  <TableCell align="center">{"#"}{new Date().getFullYear()}{"/"}{invoices.id}</TableCell>
                  <TableCell align="center">{invoices.detail ? invoices.detail : invoices.description}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="center">{invoices.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                  <TableCell align="center">{moment(invoices.dueDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell align="center">{invoices.txid || '---'}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="center">{rowStatus(invoices)}</TableCell>
                  <TableCell align="center">
                    {rowStatus(invoices) !== "Pago" ?
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleOpenInvoiceModal(invoices)}
                      >
                        PAGAR
                      </Button> :
                      <Button
                        size="small"
                        variant="outlined" 
                        /* color="secondary"
                        disabled */
                      >
                        PAGO 
                      </Button>
                    }
                    {user.super && (
                      <IconButton
                        size="small"
                        // onClick={(e) => {
                        //   setConfirmOpen(true);
                        //   setDeletingContact(contact);
                        // }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
					<TableFooter>
						<TableRow>
							<TableCell align="center">
								{i18n.t("table.totalRecords") + (invoices ? invoices?.length : 0)}
							</TableCell>  
              {user.super && (
                <> 
                  <TableCell align="center"> 
                    {`Pendente: ${totalValueOpen.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
                  </TableCell> 
                  <TableCell align="center"> 
                    {`Pago: ${totalValuePaid.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
                  </TableCell>
                  <TableCell align="center"> 
                    {`Total: ${totalValueAll.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}
                  </TableCell>
                </>
              )}
						</TableRow>
					</TableFooter>           
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Invoices;
