import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ColorModeContext from "./layout/themeContext";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
 
const App = () => {
  const [locale, setLocale] = useState(); 
  const isLoginOrSignup = window.location.pathname === "/login" || window.location.pathname === "/signup";  

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(
    (preferredTheme && isLoginOrSignup === false) ? preferredTheme : prefersDarkMode ? "dark" : "light"
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
      }
    }),
    []
  );

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px"
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8"
        }
      },
      scrollbarStylesSoft: {
        "&::-webkit-scrollbar": {
          width: "8px"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: mode === "light" ? "#F3F3F3" : "#333333"
        }
      },
      palette: {
        type: mode,
        primary: { 
          main: "#005c4b" // verde do whatsapp
          // main: "#128c7e",
        },
        secondary: {
          main: "#4caf50",
        },
        textPrimary: mode === "light" ? "#007C66" : "#FFFFFF",
        borderPrimary: mode === "light" ? "#007C66" : "#FFFFFF",
        dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
        light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
        tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
        optionsBackground: mode === "light" ? "#fafafa" : "#333",
        fancyBackground: mode === "light" ? "#fafafa" : "#333",
        total: mode === "light" ? "#fff" : "#222",
        messageIcons: mode === "light" ? "grey" : "#F3F3F3",
        inputBackground: mode === "light" ? "#FFFFFF" : "#333",
      },
      overrides: {
        MuiButton: {
          root: {
            color: mode === "dark" ? "white" : "#333", // Definindo a cor do texto dos botões como branco
            backgroundColor: mode === "dark" ? "#333" : "", // Definindo a cor de fundo dos botões como cinza escuro
            borderRadius: "4px", // Definindo o raio da borda dos botões
            padding: "8px 16px", // Definindo o espaçamento interno dos botões
          },
        },
        MuiTextField: {
          root: {
            ...(mode === "dark"
              ? {
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#333",
                    borderRadius: "4px",
                    border: 'none',
                    // '&:focus': {
                    //   border: '1px solid grey',
                    // },
                  },
                }
              : {}),
          },
        },
        ...(mode === "dark"
        ? {
            MuiInputBase: {
              input: {
                // backgroundColor: "#333",
              },
              root: {
                // border: "1px solid grey",
              },
            },
          }
        : {}), 
        MuiTab: {
          root: {
            // Estilos comuns para todas as abas
            // ...
          },
          selected: {
            // Estilos para abas selecionadas
            ...(mode === "dark"
              ? {
                  // backgroundColor: "#4caf50",  
                }
              : {}),
          },
        },               
      },      
      mode
    },
    locale
  );

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
  }, [mode]);
 
  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);
  
return ( 
    <BrowserRouter>
      <ColorModeContext.Provider value={{ colorMode }}>
        <ThemeProvider theme={theme}>          
          <Routes />
        </ThemeProvider>
      </ColorModeContext.Provider> 
    </BrowserRouter>
  );
};

export default App;
